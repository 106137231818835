<template>
  <div id="heart-particles-container" class="wrapper">
    <canvas id="heartCanvas" class="canvas"></canvas>
  </div>
</template>

<script>
export default {
  name: "HeartParticles",
  data() {
    return {
      settings: {
        particles: {
          length: 500,
          duration: 2,
          velocity: 100,
          effect: -0.75,
          size: 30,
        },
      },
    };
  },
  mounted() {
    const canvas = document.getElementById("heartCanvas");
    this.drawCanvas(canvas);
    this.renderCanvasSize();
  },
  methods: {
    PointInit() {
      function Point(x, y) {
        this.x = typeof x !== "undefined" ? x : 0;
        this.y = typeof y !== "undefined" ? y : 0;
      }
      Point.prototype.clone = function () {
        return new Point(this.x, this.y);
      };
      Point.prototype.length = function (length) {
        if (typeof length === "undefined") return Math.sqrt(this.x * this.x + this.y * this.y);
        this.normalize();
        this.x *= length;
        this.y *= length;
        return this;
      };
      Point.prototype.normalize = function () {
        const length = this.length();
        this.x /= length;
        this.y /= length;
        return this;
      };
      return Point;
    },
    ParticleInit() {
      const Point = this.PointInit();
      const that = this;
      function Particle() {
        this.position = new Point();
        this.velocity = new Point();
        this.acceleration = new Point();
        this.age = 0;
      }
      Particle.prototype.initialize = function (x, y, dx, dy) {
        this.position.x = x;
        this.position.y = y;
        this.velocity.x = dx;
        this.velocity.y = dy;
        this.acceleration.x = dx * that.settings.particles.effect;
        this.acceleration.y = dy * that.settings.particles.effect;
        this.age = 0;
      };
      Particle.prototype.update = function (deltaTime) {
        this.position.x += this.velocity.x * deltaTime;
        this.position.y += this.velocity.y * deltaTime;
        this.velocity.x += this.acceleration.x * deltaTime;
        this.velocity.y += this.acceleration.y * deltaTime;
        this.age += deltaTime;
      };
      Particle.prototype.draw = function (context, image) {
        function ease(t) {
          return --t * t * t + 1;
        }
        const size = image.width * ease(this.age / that.settings.particles.duration);
        context.globalAlpha = 1 - this.age / that.settings.particles.duration;
        context.drawImage(image, this.position.x - size / 2, this.position.y - size / 2, size, size);
      };
      return Particle;
    },
    ParticlePoolInit() {
      let particles,
        firstActive = 0,
        firstFree = 0,
        duration = this.settings.particles.duration;
      const Particle = this.ParticleInit();
      function ParticlePool(length) {
        particles = new Array(length);
        for (let i = 0; i < particles.length; i++) {
          particles[i] = new Particle();
        }
      }
      ParticlePool.prototype.add = function (x, y, dx, dy) {
        particles[firstFree].initialize(x, y, dx, dy);
        firstFree++;
        if (firstFree === particles.length) firstFree = 0;
        if (firstActive === firstFree) firstActive++;
        if (firstActive === particles.length) firstActive = 0;
      };
      ParticlePool.prototype.update = function (deltaTime) {
        let index;
        if (firstActive < firstFree) {
          for (index = firstActive; index < firstFree; index++) particles[index].update(deltaTime);
        }
        if (firstFree < firstActive) {
          for (index = firstActive; index < particles.length; index++) particles[index].update(deltaTime);
          for (index = 0; index < firstFree; index++) particles[index].update(deltaTime);
        }
        while (particles[firstActive].age >= duration && firstActive !== firstFree) {
          firstActive++;
          if (firstActive === particles.length) firstActive = 0;
        }
      };
      ParticlePool.prototype.draw = function (context, image) {
        if (firstActive < firstFree) {
          for (let index = firstActive; index < firstFree; index++) particles[index].draw(context, image);
        }
        if (firstFree < firstActive) {
          for (let index = firstActive; index < particles.length; index++) particles[index].draw(context, image);
          for (let index = 0; index < firstFree; index++) particles[index].draw(context, image);
        }
      };
      return ParticlePool;
    },
    drawCanvas(canvas) {
      const context = canvas.getContext("2d");
      const ParticlePool = this.ParticlePoolInit();
      const particles = new ParticlePool(this.settings.particles.length);
      const particleRate = this.settings.particles.length / this.settings.particles.duration; // particles/sec
      let time;
      const Point = this.PointInit();
      const that = this;

      function pointOnHeart(t) {
        return new Point(
          160 * Math.pow(Math.sin(t), 3),
          130 * Math.cos(t) - 50 * Math.cos(2 * t) - 20 * Math.cos(3 * t) - 10 * Math.cos(4 * t) + 25
        );
      }

      const image = (function () {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.width = that.settings.particles.size;
        canvas.height = that.settings.particles.size;

        function to(t) {
          const point = pointOnHeart(t);
          point.x =
            that.settings.particles.size / 2 + (point.x * that.settings.particles.size) / 350;
          point.y =
            that.settings.particles.size / 2 - (point.y * that.settings.particles.size) / 350;
          return point;
        }
        context.beginPath();
        let t = -Math.PI;
        let point = to(t);
        context.moveTo(point.x, point.y);
        while (t < Math.PI) {
          t += 0.01;
          point = to(t);
          context.lineTo(point.x, point.y);
        }
        context.closePath();
        context.fillStyle = "#ea80b0";
        context.fill();
        const image = new Image();
        image.src = canvas.toDataURL();
        return image;
      })();

      function render() {
        requestAnimationFrame(render);
        const newTime = new Date().getTime() / 1000;
        const deltaTime = newTime - (time || newTime);
        time = newTime;
        context.clearRect(0, 0, canvas.width, canvas.height);
        const amount = particleRate * deltaTime;

        for (let i = 0; i < amount; i++) {
          const pos = pointOnHeart(Math.PI - 2 * Math.PI * Math.random());
          const dir = pos.clone().length(that.settings.particles.velocity);
          particles.add(
            canvas.width / 2 + pos.x,
            canvas.height / 2 - pos.y,
            dir.x,
            -dir.y
          );
        }

        particles.update(deltaTime);
        particles.draw(context, image);
      }

      function onResize() {
        canvas.width = canvas.clientWidth;
        canvas.height = canvas.clientHeight;
      }

      window.onresize = onResize;

      setTimeout(function () {
        onResize();
        render();
      }, 10);
    },
    renderCanvasSize() {
      const canvas = document.getElementById("heartCanvas");
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    },
  },
};
</script>

<style scoped>
#heart-particles-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #000;
}
.canvas {
  width: 100%;
  height: 100%;
}
</style> 